<template>
    <main id="list-review" class="container">
        <div class="content">
            <div class="content_head">
                <div class="inner">
                    <div class="breadcrumb">
                        <ul>
                            <li><a href="#" class="ico_home" title="Home"></a></li>
                            <li>
                                {{boardInfo.boardName}}
                            </li>
                        </ul>
                    </div>
                    <h2 class="page_title">
                        {{boardInfo.boardName}}
                    </h2>
                </div>
            </div>
            <div class="content_body">
                <div class="inner">
                    <div class="board_review">

                        <!-- 컨텐츠 있을 때 -->
                        <ul class="review_list">
                            <template v-if="boardList.length > 0">
                                <li class="review_item" v-for="(item,index) in boardList" :key="'dd-'+index" >
                                    <a @click="goView(item)">
                                        <strong class="tit">{{item.title}}</strong>
                                        <p class="desc">{{item.contentText}}</p>
                                        <p class="date">{{item.createDate | dateFilter}}</p>
                                    </a>
                                </li>
                            </template>
                            <template v-else>
                                <li class="content_none">
                                    등록된 컨텐츠가 없습니다.
                                </li>
                            </template>
                        </ul>
                        <div class="button_area align_right" v-if="isLogin()">
                            <button type="button" class="btn_write btn_primary" @click="goWrite()">글쓰기</button>
                        </div>
                        <div class="pagination">
                            <a href="#" v-if="page-1 < -1 " class="prev" @click="goPage(page-1)"><span class="blind">이전 페이지</span></a>

                            <a v-for="(item,index) in  (parseInt((boardCount / size) ) + 1)" :key="'dd2-'+index" href="#" @click="goPage(item-1)" :class="{ 'current': item == (page +1) }">{{item}}</a>

                            <a href="#" class="next" v-if="(page+1) < (parseInt((boardCount / size))+1)">
                                <span class="blind" @click="goPage(page+1)">다음 페이지</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: "listReview",
        props: {
            forApp: {
                type: Boolean,
                default: false,
            },
            boardInfo: Object,
            noticeList: Array,
            boardList: Array,
            page: Number,
            size: Number,
            boardCount: Number
        },
        data(){
            return {
                // isLogin: this.$session.get('HWSSessions') == null ? false : true
            }

        },
        mounted() {
        },
        methods: {

            goView( _item ) {
                this.goReviewBoard( _item.boardKey, _item.idx )
                this.$emit("goBoardOne", _item.idx);
            },
            goWrite(){
                location.href = "/review/"+this.boardInfo.boardKey+"/form"
            },
            goPage(page) {


                this.$emit('changePage', page);

            },

        },
        watch: {
            'page': function (data) {
                console.log(data)
            }
        }
    }
</script>

<style scoped>

</style>